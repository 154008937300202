<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑banner' : '新增banner'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 文案 -->
      <a-form-item
        label="文案"
        name="text"
        :rules="[{ required: true, message: '请输入文案' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.text"
        />
      </a-form-item>
      <!-- 顺序 -->
      <a-form-item
        label="顺序"
        name="sort"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.sort"
        />
      </a-form-item>
      <!-- 跳转页面 -->
      <a-form-item
        label="跳转页面"
        name="type"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          v-model:value="formState.type"
          @change="onRadioChange"
        >
          <a-radio :value="1">短剧</a-radio>
          <a-radio :value="2">活动页</a-radio>
          <a-radio :value="3">自定义</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        v-if="formState.type === 1"
        label="短剧"
        name="play"
        :rules="[{ required: true, message: '请选择短剧' }]"
      >
        <a-select
          show-search
          style="width: 350px"
          placeholder="请选择"
          v-model:value="formState.play"
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="drama in dramaList"
            :key="`${drama.id}-${drama.remark_name}`"
            :value="drama.id"
          >
            {{ drama.remark_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 活动 -->
      <a-form-item
        v-else-if="formState.type === 2"
        label="活动"
        name="activity_id"
        :rules="[{ required: true, message: '请选择活动' }]"
      >
        <a-select
          show-search
          style="width: 350px"
          placeholder="请选择"
          v-model:value="formState.activity_id"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="drama in activityList"
            :key="`${drama.id}-${drama.title}`"
            :value="drama.id"
          >
            {{ drama.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 自定义 -->
      <a-form-item
        v-else-if="formState.type === 3"
        label="路径"
        name="text"
        :rules="[{ required: true, message: '路径' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.redirect_url"
        />
      </a-form-item>
      <!-- 图片 -->
      <a-form-item
        label="图片"
        name="img"
        :rules="[{ required: true }]"
      >
        <!-- 上传组件 -->
        <template v-if="platform == 7">
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="BannersAdd"
            :fileNumber="1"
            :imgSizeMode="3"
            :imgSizeWidth="1200"
            :imgSizeHeight="300"
            imgSizeError="图片尺寸为 1200 * 300"
            :imgScaleMode="1"
            :imgScaleWidth="1200"
            :imgScaleHeight="300"
            imgScaleError="图片尺寸比例不正确"
            :beforeUploadPro="beforeUploadPro"
            :fileList="formState.img"
            :uploadBucket="$pub.BUCKET_PUB()"
          ></Upload>
          <div class="form-upload-hint">支持格式：.png .jpg，尺寸1200x300</div>
        </template>
        <template v-else>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="BannersAdd"
            :fileNumber="1"
            :imgSizeMode="3"
            :imgSizeWidth="670"
            :imgSizeHeight="332"
            imgSizeError="图片尺寸为 670 * 332"
            imgScaleError="图片尺寸比例不正确"
            :beforeUploadPro="beforeUploadPro"
            :fileList="formState.img"
            :uploadBucket="$pub.BUCKET_PUB()"
          ></Upload>
          <div class="form-upload-hint">支持格式：.png .jpg，尺寸670x332</div>
        </template>
        <div class="form-upload-list" v-if="formState.img.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.img"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('img', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Upload from '@/components/Upload'
import Pub from '@/utils/public'
import ImageView from '@/components/ImageView'
import { FrontSource} from '@/utils/constantList'
import { frontSourceDetail, frontBannerEdit, projectDramaAll, frontBannerActivityList } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// source类型
const source = FrontSource.banner
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// platform
let platform = ref(undefined)
// id
let id = ref(null)
// 短剧列表
let dramaList = ref([])
// 活动列表
let activityList = ref([])
// 剧集信息
let formState = reactive({
  // 文案
  text: undefined,
  // 短剧
  play: undefined,
  // 跳转页面类型
  type: 1,
  // 活动ID
  activity_id: undefined,
  // 顺序
  sort: undefined,
  // 图片
  img: []
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 当前平台
  platform.value = params.platform
  // id
  id.value = params.id
  // 初始化
  if (id.value) {
    // 获取详情
    getFrontSourceDetail()
  }
  // 获取选择项列表
  getSelectList()
}

// 获取选择项列表
function getSelectList () {
  projectDramaAll({ platform_id: platform.value }).then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      dramaList.value = data
    }
  })
  frontBannerActivityList().then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      activityList.value = data
    }
  })
}

function onRadioChange() {
  formState.play = undefined
  formState.activity_id = undefined
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 获取配置详情
function getFrontSourceDetail () {
  isLoading.value = true
  const params = {
    source,
    id: id.value
  }
  frontSourceDetail(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      formState.type = data.type
      formState.activity_id = data.activity_id
      // 文案
      formState.text = data.description
      // 排序
      formState.sort = data.sort
      // 选中短剧
      formState.play = data.drama_id
      // 图片
      formState.img = [{
        url: data.path_url
      }]
      // 路径
      formState.redirect_url = data.redirect_url
      // 将配置中已经下架的短剧，拼在列表的后面（保证正常展示）
      dramaList.value = Pub.mergeArr(dramaList.value, [
        {
          id: data.drama_id,
          name: data.drama_name,
          project_drama_id: data.project_drama_id
        }
      ], 'id')
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    formState.play = undefined
    formState.activity_id = undefined
    visible.value = false
    formState.redirect_url = undefined
  }
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 准备上传
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 表单校验
function validator (rule, value) {
  // 封面
  if (rule.field === 'sort') {
    if (!value) {
      return Promise.reject('请输入顺序')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    // 跳转路径要用的项目下的短剧 id
    const project_drama_id = formState.type === 1 ? dramaList.value.filter(item => item.id === formState.play)[0].project_drama_id : ''
      // 跳转地址为指定格式，拼接好选中的剧集id，默认跳转第一集
    const redirect_url = formState.type === 3 ? formState.redirect_url :
      (formState.type === 1 ? `/pages-play/index?id=${project_drama_id}&eq=1` : `/pages-activity/index?activity_id=${formState.activity_id}`)
    const params = {
      platform_id: platform.value,
      id: id.value,
      type: formState.type,
      activity_id: formState.activity_id,
      sort: formState.sort,
      description: formState.text,
      drama_id: formState.play,
      redirect_url: redirect_url,
      path_url: formState.img[0].url,
    }
    frontBannerEdit(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  // width: 118px;
  // height: 148px;
  width: 208px;
  height: 124px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>